// @flow
import BlogImageUploadModel from './BlogImageUploadModel';

class BlogImageBatchUploadModel {
    _postId: ?number;
    _blogImageUploadModels: ?BlogImageUploadModel[];
    _convertToJpeg: ?boolean;

    static get Empty() : BlogImageBatchUploadModel{
        return new BlogImageBatchUploadModel(null, null, null);
    }

    constructor(postId: ?number, blogImageUploadModels: ?BlogImageUploadModel[], convertToJpeg: ?boolean) {
        this._postId = postId;
        this._blogImageUploadModels = blogImageUploadModels;
        this._convertToJpeg = convertToJpeg;
    }

    get postId(): ?number {
        return this._postId;
    }

    set postId(value: ?number) {
        this._postId = value;
    }


    get blogImageUploadModels(): ?BlogImageUploadModel[] {
        return this._blogImageUploadModels;
    }

    set blogImageUploadModels(value: ?BlogImageUploadModel[]) {
        this._blogImageUploadModels = value;
    }


    get convertToJpeg(): ?boolean {
        return this._convertToJpeg;
    }

    set convertToJpeg(value: ?boolean) {
        this._convertToJpeg = value;
    }
}


export default BlogImageBatchUploadModel;
