// @flow

class BlogImageModel {
    _name: ?string;
    _type: ?string;
    _url: ?string;
    _thumbnailUrl: ?string;

    static get Empty() : BlogImageModel{
        return new BlogImageModel(null, null, null);
    }

    constructor(name: ?string, url: ?string, thumbnailUrl: ?string) {
        this._name = name;
        this._url = url;
        this._thumbnailUrl = thumbnailUrl;
    }


    get name(): ?string {
        return this._name;
    }

    set name(value: ?string) {
        this._name = value;
    }

    get url(): ?string {
        return this._url;
    }

    set url(value: ?string) {
        this._url = value;
    }

    get thumbnailUrl(): ?string {
        return this._thumbnailUrl;
    }

    set thumbnailUrl(value: ?string) {
        this._thumbnailUrl = value;
    }


}


export default BlogImageModel;
