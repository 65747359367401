// @flow
import CommonRequestModel from '../Common/DataPageRequestModel';

// A base class for data "page" requests
class BlogPostPreviewRequestModel extends CommonRequestModel {
    _tag: ?string;
    _filter: ?string;

    static get Empty() : BlogPostPreviewRequestModel{
        return new BlogPostPreviewRequestModel(0, 0, null, false, null, null);
    }

    constructor(recordsPerPage: number, pageIndex: number, sortBy: ?string, sortAsc: boolean, filter: ?string, tag: ?string) {
        super(recordsPerPage, pageIndex, sortBy, sortAsc);
        this._filter = filter;
        this._tag = tag;
    }

    get filter(): ?string {
        return this._filter;
    } 

    set filter(value: ?string) {
        return this._filter = value;
    }


    get tag(): ?string {
        return this._tag;
    }

    set tag(value: ?string) {
        return this._tag = value;
    }



}


export default BlogPostPreviewRequestModel;
