// @flow
import AuthStatusModel from '../lib/Models/Auth/AuthStatusModel';
import LoginModel from '../lib/Models/Auth/LoginModel';

import BlogPostPreviewModel from '../lib/Models/Blog/BlogPostPreviewModel';
import BlogPostPreviewRequestModel from '../lib/Models/Blog/BlogPostPreviewRequestModel';
import BlogViewPostModel from '../lib/Models/Blog/BlogViewPostModel';
import BlogEditPostModel from '../lib/Models/Blog/BlogEditPostModel';
import BlogImageModel from '../lib/Models/Blog/BlogImageModel';
import BlogImageUploadModel from '../lib/Models/Blog/BlogImageUploadModel';
import BlogImageBatchUploadModel from '../lib/Models/Blog/BlogImageBatchUploadModel';
import BlogAdminEditModel from '../lib/Models/Blog/BlogAdminEditModel';
import BlogDraftPostSummaryModel from '../lib/Models/Blog/BlogDraftPostSummaryModel';

import DataPageRequestModel from '../lib/Models/Common/DataPageRequestModel';
import DataPageResponseModel from '../lib/Models/Common/DataPageResponseModel';
import ActionCompleteModel from '../lib/Models/Common/ActionCompleteModel';
import KeyValuePairModel from '../lib/Models/Common/KeyValuePairModel';
import NumericKeyValuePairModel from '../lib/Models/Common/NumericKeyValuePairModel';

import SharedUtils from '../lib/SharedUtils';
import SharedRoutes from '../lib/SharedRoutes';
import TelemetrySearchModel from "../lib/Models/Telemetry/TelemetrySearchModel";
import TelemetryDataModel from "../lib/Models/Telemetry/TelemetryDataModel";
import SecretFoundDataModel from "../lib/Models/Telemetry/SecretFoundDataModel";
import SecretFoundSearchResultModel from "../lib/Models/Telemetry/SecretFoundSearchResultModel";
import CutscenePlayedDataModel from "../lib/Models/Telemetry/CutscenePlayedDataModel";
import CutscenePlayedSearchResultModel from "../lib/Models/Telemetry/CutscenePlayedSearchResultModel";
import TelemetryLevelCompletionAnalysisDataModel from "../lib/Models/Telemetry/TelemetryLevelCompletionAnalysisDataModel";
import TelemetryPlayerRetentionAnalysisDataModel from "../lib/Models/Telemetry/TelemetryPlayerRetentionAnalysisDataModel";

// Models - Auth
exports.AuthStatusModel = AuthStatusModel;
exports.LoginModel = LoginModel;

// Models - Blog
exports.BlogPostPreviewModel = BlogPostPreviewModel;
exports.BlogPostPreviewRequestModel = BlogPostPreviewRequestModel;
exports.BlogViewPostModel = BlogViewPostModel;
exports.BlogEditPostModel = BlogEditPostModel;
exports.BlogImageModel = BlogImageModel;
exports.BlogAdminEditModel = BlogAdminEditModel;
exports.BlogImageUploadModel = BlogImageUploadModel;
exports.BlogImageBatchUploadModel = BlogImageBatchUploadModel;
exports.BlogDraftPostSummaryModel = BlogDraftPostSummaryModel;

// Models - Telemetry
exports.TelemetrySearchModel = TelemetrySearchModel;
exports.TelemetryDataModel = TelemetryDataModel;
exports.SecretFoundDataModel = SecretFoundDataModel;
exports.SecretFoundSearchResultModel = SecretFoundSearchResultModel;
exports.CutscenePlayedDataModel = CutscenePlayedDataModel;
exports.CutscenePlayedSearchResultModel = CutscenePlayedSearchResultModel;
exports.TelemetryLevelCompletionAnalysisDataModel = TelemetryLevelCompletionAnalysisDataModel;
exports.TelemetryPlayerRetentionAnalysisDataModel = TelemetryPlayerRetentionAnalysisDataModel;

// Models - Common
exports.DataPageRequestModel = DataPageRequestModel;
exports.DataPageResponseModel = DataPageResponseModel;
exports.ActionCompleteModel = ActionCompleteModel;
exports.KeyValuePairModel = KeyValuePairModel;
exports.NumericKeyValuePairModel = NumericKeyValuePairModel;

// Shared
exports.SharedUtils = SharedUtils;
exports.SharedRoutes = SharedRoutes;
// another
//exports.XXX = SharedRoutes;