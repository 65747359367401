// @flow

class TelemetryPlayerRetentionAnalysisDataModel {
    _fullLevelId: ?string;
    _wasPlayersLastLevelCount: ?number;

    static get Empty(): TelemetryPlayerRetentionAnalysisDataModel {
        return new TelemetryPlayerRetentionAnalysisDataModel(null, null);
    }

    constructor(fullLevelId: ?string,
                wasPlayersLastLevelCount: ?number) {
        this._fullLevelId = fullLevelId;
        this._wasPlayersLastLevelCount = wasPlayersLastLevelCount;
    }

    get fullLevelId(): ?string { return this._fullLevelId; }
    set fullLevelId(value: ?string) { this._fullLevelId = value; }

    get wasPlayersLastLevelCount(): ?number { return this._wasPlayersLastLevelCount; }
    set wasPlayersLastLevelCount(value: ?number) { this._wasPlayersLastLevelCount = value; }
}


export default TelemetryPlayerRetentionAnalysisDataModel;
