// @flow

// A base class for data "page" requests
class ActionCompleteModel {
    _isSuccess: boolean;
    _message: string;

    static get Empty() : ActionCompleteModel{
        return new ActionCompleteModel(true, "");
    }

    constructor(isSuccess: boolean, message: string) {
        this._isSuccess = isSuccess;
        this._message = message;
    }

    get isSuccess(): boolean {
        return this._isSuccess;
    }

    set isSuccess(value: boolean) {
        this._isSuccess = value;
    }


    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }

}


export default ActionCompleteModel;
