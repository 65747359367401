// @flow

class BlogAdminEditModel {
    _tags: Array<string>;

    static get Empty() : BlogAdminEditModel{
        return new BlogAdminEditModel([]);
    }

    constructor(tags: Array<string>) {
        this._tags = tags;
    }


    get tags(): Array<string> {
        return this._tags;
    }

    set tags(value: Array<string>) {
        this._tags = value;
    }


}


export default BlogAdminEditModel;
