// @flow

// A base class for data "page" requests
class DataPageResponseModel {
    _totalRecords: number;
    _totalPages: number;
    _pageIndex: number;
    _records: Array<Object>;
    _recordType: any;

    static get Empty() : DataPageResponseModel{
        return new DataPageResponseModel(0, 0, 0, [], null);
    }

    constructor(totalRecords: number, totalPages: number, pageIndex: number, records: Array<Object>, recordType: any) {
        this._totalRecords = totalRecords;
        this._totalPages = totalPages;
        this._pageIndex = pageIndex;
        this._records = records;
        this._recordType = recordType;
    }

    get totalRecords(): number {
        return this._totalRecords;
    }

    set totalRecords(value: number) {
        this._totalRecords = value;
    }


    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: number) {
        return this._totalPages = value;
    }


    get pageIndex(): number {
        return this._pageIndex;
    }

    set pageIndex(value: number) {
        return this._pageIndex = value;
    }


    get records(): Array<Object> {
        return this._records;
    }

    set records(value: Array<Object>) {
        return this._records = value;
    }


    get resultType(): any {
        return this._recordType;
    }

    set resultType(value: any) {
        return this._recordType = value;
    }

}


export default DataPageResponseModel;
