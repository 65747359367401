// @flow

class BlogImageUploadModel {
    _fileName: ?string;
    _fileType: ?string;
    _fileDataUri: any;

    static get Empty() : BlogImageUploadModel{
        return new BlogImageUploadModel( null, null, null);
    }

    constructor(fileName: ?string, fileType: ?string, fileDataUri: ?string) {
        this._fileName = fileName;
        this._fileType = fileType;
        this._fileDataUri = fileDataUri;

    }

    get fileName(): ?string {
        return this._fileName;
    }

    set fileName(value: ?string) {
        this._fileName = value;
    }

    get fileType(): ?string {
        return this._fileType;
    }

    set fileType(value: ?string) {
        this._fileType = value;
    }

    get fileDataUri(): any {
        return this._fileDataUri;
    }

    set fileDataUri(value: any) {
        this._fileDataUri = value;
    }
}


export default BlogImageUploadModel;
