// @flow

class BlogViewPostModel {
    _postId: ?number;
    _longId: ?string;
    _title: ?string;
    _html: ?string;
    _css: ?string;
    _date: ?Date;
    _bannerImageUrl: ?string;
    _tags: Array<string>;
    _excerpt: ?string

    static get Empty() : BlogViewPostModel{
        return new BlogViewPostModel(null, null, null, null, null, null, null, [], null);
    }

    constructor(postId: ?number, longId: ?string, title: ?string, html: ?string, css: ?string, date: ?Date, bannerImageUrl: ?string, tags: Array<string>, excerpt: ?string) {
        this._postId = postId;
        this._longId = longId;
        this._title = title;
        this._html = html;
        this._css = css;
        this._date = date;
        this._bannerImageUrl = bannerImageUrl;
        this._tags = tags;
        this._excerpt = excerpt;
    }

    get postId(): ?number {
        return this._postId;
    }

    set postId(value: ?number) {
        this._postId = value;
    }

    /** Gets the long text-based ID of this post */
    get longId(): ?string {
        return this._longId;
    }

    set longId(value: ?string) {
        this._longId = value;
    }



    get title(): ?string {
        return this._title;
    }

    set title(value: ?string) {
        return this._title = value;
    }



    get html(): ?string {
        return this._html;
    }

    set html(value: ?string) {
        return this._html = value;
    }



    get css(): ?string {
        return this._css;
    }

    set css(value: ?string) {
        return this._css = value;
    }




    get date(): ?Date {
        return this._date;
    }

    set date(value: ?Date) {
        this._date = value;
    }


    get bannerImageUrl(): ?string {
        return this._bannerImageUrl;
    }

    set bannerImageUrl(value: ?string) {
        this._bannerImageUrl = value;
    }

    get tags(): Array<string> {
        return this._tags;
    }

    set tags(value: Array<string>) {
        this._tags = value;
    }


    get excerpt(): ?string {
        return this._excerpt;
    }

    set excerpt(value: ?string) {
        this._excerpt = value;
    }
}


export default BlogViewPostModel;
