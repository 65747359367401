// @flow

class CutscenePlayedDataModel {
    _cutsceneId: ?number;
    _playerId: ?string;
    _wasSkipped: ?boolean;
    _isAutoSkip: ?boolean;
    _hasPreviouslyCompletedLevel: ?boolean;
    _skipTime: ?number;
    _fullCutsceneDuration: ?number;
    _date: ?Date;
    _validationHash: ?string;

    static get Empty(): CutscenePlayedDataModel {
        return new CutscenePlayedDataModel(null, null, null, null, null, null, null, null, null);
    }

    constructor(cutsceneId: ?number,
                playerId: ?string,
                wasSkipped: ?boolean,
                isAutoSkip: ?boolean,
                hasPreviouslyCompletedLevel: ?boolean,
                skipTime: ?number,
                fullCutsceneDuration: ?number,
                date: ?Date,
                validationHash: ?string) {
        this._cutsceneId = cutsceneId;
        this._playerId = playerId;
        this._wasSkipped = wasSkipped;
        this._isAutoSkip = isAutoSkip;
        this._hasPreviouslyCompletedLevel = hasPreviouslyCompletedLevel;
        this._skipTime = skipTime;
        this._fullCutsceneDuration = fullCutsceneDuration;
        this._date = date;
        this._validationHash = validationHash;
    }

    get cutsceneId(): ?number { return this._cutsceneId; }
    set cutsceneId(value: ?number) { this._cutsceneId = value; }

    get playerId(): ?string { return this._playerId; }
    set playerId(value: ?string) { this._playerId = value; }

    get wasSkipped(): ?boolean { return this._wasSkipped; }
    set wasSkipped(value: ?boolean) { this._wasSkipped = value; }

    get isAutoSkip(): ?boolean { return this._isAutoSkip; }
    set isAutoSkip(value: ?boolean) { this._isAutoSkip = value; }

    get hasPreviouslyCompletedLevel(): ?boolean { return this._hasPreviouslyCompletedLevel; }
    set hasPreviouslyCompletedLevel(value: ?boolean) { this._hasPreviouslyCompletedLevel = value; }

    get skipTime(): ?number { return this._skipTime; }
    set skipTime(value: ?number) { this._skipTime = value; }

    get fullCutsceneDuration(): ?number { return this._fullCutsceneDuration; }
    set fullCutsceneDuration(value: ?number) { this._fullCutsceneDuration = value; }

    get date(): ?Date { return this._date; }
    set date(value: ?Date) { this._date = value; }

    get validationHash(): ?string { return this._validationHash; }
    set validationHash(value: ?string) { this._validationHash = value; }
}


export default CutscenePlayedDataModel;
