// @flow

class BlogPostPreviewModel {
    _postId: ?number;
    _longId: ?string;
    _title: ?string;
    _excerpt: ?string;
    _date: ?Date;
    _bannerImageUrl: ?string;
    _bannerImageThumbnailUrl: ?string;
    _tags: Array<string>;

    static get Empty() : BlogPostPreviewModel{
        return new BlogPostPreviewModel(null, null, null, null, null, null, null, []);
    }

    constructor(postId: ?number, longId: ?string, title: ?string, excerpt: ?string, date: ?Date, bannerImageUrl: ?string, bannerImageThumbnailUrl: ?string, tags: Array<string>) {
        this._postId = postId;
        this._longId = longId;
        this._title = title;
        this._excerpt = excerpt;
        this._date = date;
        this._bannerImageUrl = bannerImageUrl;
        this._bannerImageThumbnailUrl = bannerImageThumbnailUrl;
        this._tags = tags;
    }
 
    get postId(): ?number {
        return this._postId;
    }

    set postId(value: ?number) {
        this._postId = value;
    }

    get longId(): ?string {
        return this._longId;
    }

    set longId(value: ?string) {
        this._longId = value;
    }


    get title(): ?string {
        return this._title;
    }

    set title(value: ?string) {
        return this._title = value;
    }


    get excerpt(): ?string {
        return this._excerpt;
    }

    set excerpt(value: ?string) {
        this._excerpt = value;
    }


    get date(): ?Date {
        return this._date;
    }

    set date(value: ?Date) {
        this._date = value;
    }


    get bannerImageUrl(): ?string {
        return this._bannerImageUrl;
    }

    set bannerImageUrl(value: ?string) {
        this._bannerImageUrl = value;
    }

    get bannerImageThumbnailUrl(): ?string {
        return this._bannerImageThumbnailUrl;
    }

    set bannerImageThumbnailUrl(value: ?string) {
        this._bannerImageThumbnailUrl = value;
    }

    get tags(): Array<string> {
        return this._tags;
    }

    set tags(value: Array<string>) {
        this._tags = value;
    }

}


export default BlogPostPreviewModel;
