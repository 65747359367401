// @flow

class SecretFoundDataModel {
    _secretId: ?number;
    _playerId: ?string;
    _totalSecretsFound: ?number;
    _date: ?Date;
    _validationHash: ?string;

    static get Empty(): SecretFoundDataModel {
        return new SecretFoundDataModel(null, null, null, null, null);
    }

    constructor(secretId: ?number,
                playerId: ?string,
                totalSecretsFound: ?number,
                date: ?Date,
                validationHash: ?string,) {
        this._secretId = secretId;
        this._playerId = playerId;
        this._totalSecretsFound = totalSecretsFound;
        this._date = date;
        this._validationHash = validationHash;
    }

    get secretId(): ?number { return this._secretId; }
    set secretId(value: ?number) { this._secretId = value; }

    get playerId(): ?string { return this._playerId; }
    set playerId(value: ?string) { this._playerId = value; }

    get totalSecretsFound(): ?number { return this._totalSecretsFound; }
    set totalSecretsFound(value: ?number) { this._totalSecretsFound = value; }

    get date(): ?Date { return this._date; }
    set date(value: ?Date) { this._date = value; }

    get validationHash(): ?string { return this._validationHash; }
    set validationHash(value: ?string) { this._validationHash = value; }

}


export default SecretFoundDataModel;
