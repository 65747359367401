// @flow

class LoginModel {
    _username: ?string;
    _password: ?string;

    static get Empty() : LoginModel{
        return new LoginModel(null, null);
    }

    constructor(username: ?string, password: ?string) {
        this._username = username;
        this._password = password;
    }


    get username(): ?string {
        return this._username;
    }

    set username(value: ?string) {
        return this._username = value;
    }


    get password(): ?string {
        return this._password;
    }

    set password(value: ?string) {
        this._password = value;
    }

}

export default LoginModel;
