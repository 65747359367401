// @flow
import SharedUtils from './SharedUtils';

/** A class of constants containing routes used in both the Server and the UI. */
class SharedRoutes {


    static get UI()  {

        class UI {

            static get Error() {
                class Error {
                    static get Path(): string {
                        return SharedUtils.mergeRoute( "/error", Error.MessageParam);
                    };

                    static get MessageParam(): string {
                        return ":message?";
                    }
                }

                return Error;
            };

            static get Privacy() {
                class Privacy {
                    static get Path(): string {
                        return "/privacy";
                    };
                }

                return Privacy;
            };


            static get LoginLanding() {
                class LoginLanding {
                    static get Path(): string {
                        return "/loginLanding";
                    };
                }

                return LoginLanding;
            };

            static get Blog() {

                class Blog {
                    static get Root(): string {
                        return "/blog";
                    };

                    static get Home() {
                        class Home {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, Home.SearchTagParam);
                            };

                            static get SearchTagParam(): string {
                                return ":searchTag?";
                            };
                        }

                        return Home;
                    };

                    static get ViewPost() {
                        class ViewPost {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/viewPost", ViewPost.PostIdParam, ViewPost.LongIdParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId";
                            };

                            static get LongIdParam(): string {
                                return ":longId";
                            };
                        }

                        return ViewPost;
                    };


                    static get AllPosts() {
                        class AllPosts {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/allPosts", AllPosts.PageNumberParam);
                            };

                            static get PageNumberParam(): string {
                                return ":pageNumber";
                            };
                        }

                        return AllPosts;
                    };

                    static get EditPost() {
                        class EditPost {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/editPost", EditPost.PostIdParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId?";
                            };
                        }

                        return EditPost;
                    };


                    static get Admin() {
                        class Admin {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/admin");
                            };
                        }

                        return Admin;
                    };
                }

                return Blog;
            };




            static get Admin() {

                class Admin {
                    static get Root(): string {
                        return "/admin";
                    };

                    static get Home() {
                        class Home {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Admin.Root);
                            };

                        }

                        return Home;
                    };


                    static get Telemetry() {
                        class Telemetry {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Admin.Root, "/telemetry");
                            };
                        }

                        return Telemetry;
                    };

                    static get Jira() {
                        class Jira {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Admin.Root, "/jira");
                            };
                        }

                        return Jira;
                    };

                    static get JiraReport() {
                        class JiraReport {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Admin.Root, "/jiraReport");
                            };
                        }

                        return JiraReport;
                    };

                }

                return Admin;
            };

        }

        return UI; 
    }

    static get Server() {
        class Server {


            static get SiteMap() {
                class SiteMap {
                    static get Path(): string {
                        return "/siteMap";
                    };
                }

                return SiteMap;
            };

            static get Rss() {
                class Rss {
                    static get Path(): string {
                        return "/rss";
                    };
                }

                return Rss;
            };

            static get Misc() {
                class Misc {
                    static get Root() {
                        return "/misc"
                    };

                    static get GraviaNewsSubscribe() {
                        class GraviaNewsSubscribe {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Misc.Root, "/graviaNewsSubscribe");
                            };
                        }

                        return GraviaNewsSubscribe;
                    };
                }

                return Misc;
            };


            static get Telemetry() {
                class Telemetry {
                    static get Root() {
                        return "/telemetry"
                    };

                    // Core Telemetry

                    static get AddTelemetryData() {
                        class AddTelemetryData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/addTelemetryData");
                            };
                        }

                        return AddTelemetryData;
                    };


                    static get TelemetryBinaryData() {
                        class TelemetryBinaryData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/telemetryBinaryData");
                            };
                        }

                        return TelemetryBinaryData;
                    };

                    static get TelemetryTabularData() {
                        class TelemetryTabularData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/telemetryTabularData");
                            };
                        }

                        return TelemetryTabularData;
                    };

                    static get TelemetryPlayerNames() {
                        class TelemetryPlayerNames {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/telemetryPlayerNames");
                            };
                        }

                        return TelemetryPlayerNames;
                    };

                    static get TelemetryLevelIds() {
                        class TelemetryLevelIds {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/telemetryLevelIds");
                            };
                        }

                        return TelemetryLevelIds;
                    };



                    // Secrets Found data

                    static get AddSecretFoundData() {
                        class AddSecretFoundData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/addSecretFoundData");
                            };
                        }

                        return AddSecretFoundData;
                    };

                    static get SetTelemetryViewedState() {
                        class SetTelemetryViewedState {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/setTelemetryViewedState");
                            };
                        }

                        return SetTelemetryViewedState;
                    };



                    static get SecretFoundTabularData() {
                        class SecretFoundTabularData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/secretFoundTabularData");
                            };
                        }

                        return SecretFoundTabularData;
                    };


                    // Cutscene Played data

                    static get AddCutscenePlayedData() {
                        class AddCutscenePlayedData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/addCutscenePlayedData");
                            };
                        }

                        return AddCutscenePlayedData;
                    };


                    static get CutscenePlayedTabularData() {
                        class CutscenePlayedTabularData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/cutscenePlayedTabularData");
                            };
                        }

                        return CutscenePlayedTabularData;
                    };


                    static get TelemetryLevelCompletionAnalysisData() {
                        class TelemetryLevelCompletionAnalysisData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/telemetryLevelCompletionAnalysisData");
                            };
                        }

                        return TelemetryLevelCompletionAnalysisData;
                    };

                    static get TelemetryPlayerRetentionAnalysisData() {
                        class TelemetryPlayerRetentionAnalysisData {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Telemetry.Root, "/telemetryPlayerRetentionAnalysisData");
                            };
                        }

                        return TelemetryPlayerRetentionAnalysisData;
                    };


                }

                return Telemetry;
            };

            static get Blog() {

                class Blog {
                    static get Root(): string {
                        return "/blog"
                    };

                    static get PostPreviews() {
                        class PostPreviews {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/postPreviews");
                            };
                        }

                        return PostPreviews;
                    };


                    static get InitialPostPreviews() {
                        class InitialPostPreviews {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/initialPostPreviews", InitialPostPreviews.RecordsPerPageParam);
                            };

                            static get RecordsPerPageParam() {
                                return ":recordsPerPage";
                            };
                        }

                        return InitialPostPreviews;
                    };

                    static get Tags() {
                        class Tags {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/tags");
                            };
                        }

                        return Tags;
                    };

                    static get AllTags() {
                        class AllTags {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/allTags");
                            };
                        }

                        return AllTags;
                    };

                    static get ViewPost() {
                        class ViewPost {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/viewPost", ViewPost.PostIdParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId";
                            };
                        }

                        return ViewPost;
                    };

                    static get EditPost() {
                        class EditPost {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/editPost", EditPost.PostIdParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId";
                            };
                        }

                        return EditPost;
                    };

                    static get DraftPost() {
                        class DraftPost {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/draftPost", DraftPost.DraftGuidParam);
                            };

                            static get DraftGuidParam(): string {
                                return ":draftGuid";
                            };
                        }

                        return DraftPost;
                    };


                    static get DraftPosts() {
                        class DraftPosts {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/draftPosts", DraftPosts.PostIdParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId";
                            };
                        }

                        return DraftPosts;
                    };


                    static get Images() {
                        class Images {

                            static get Path() {
                                return SharedUtils.mergeRoute( Blog.Root, "/images", Images.PostIdParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId";
                            };
                        }

                        return Images;
                    };


                    static get ImageUpload() {
                        class ImageUpload {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/imageUpload");
                            };
                        }

                        return ImageUpload;
                    };

                    static get ImageDelete() {
                        class ImageDelete {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/imageDelete", ImageDelete.PostIdParam, ImageDelete.ImageNameParam);
                            };

                            static get PostIdParam(): string {
                                return ":postId";
                            };

                            static get ImageNameParam(): string {
                                return ":imageName";
                            };
                        }

                        return ImageDelete;
                    };

                    static get Admin() {
                        class Admin {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/admin");
                            };

                        }

                        return Admin;
                    };


                    static get NewPostId() {
                        class NewPostId {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/newPostId");
                            };
                        }

                        return NewPostId;
                    };


                    static get DeletePost() {
                        class DeletePost {

                            static get Path(): string {
                                return SharedUtils.mergeRoute( Blog.Root, "/deletePost");
                            };
                        }

                        return DeletePost;
                    };


                }

                return Blog;
            };

            static get Auth() {

                class Auth {
                    static get Root(): string {
                        return "/auth"
                    };

                    static get Facebook() {
                        class Facebook {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Auth.Root, "/facebook");
                            };
                        }

                        return Facebook;
                    };

                    static get FacebookCallback() {
                        class FacebookCallback {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Auth.Root, "/facebook/callback");
                            };
                        }

                        return FacebookCallback;
                    };

                    static get AuthenticationStatus() {
                        class AuthenticationStatus {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Auth.Root, "/authStatus");
                            };
                        }

                        return AuthenticationStatus;
                    };

                    static get Login() {
                        class Login {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Auth.Root, "/login");
                            };
                        }

                        return Login;
                    };

                    static get Logout() {
                        class Logout {
                            static get Path(): string {
                                return SharedUtils.mergeRoute( Auth.Root, "/logout");
                            };
                        }

                        return Logout;
                    };

                }

                return Auth;
            };

        }
        return Server;
    }
}

export default SharedRoutes;