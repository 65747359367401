// @flow

class BlogEditPostModel {
    _guid: ?string;
    _postId: ?number;
    _longId: ?string;
    _title: ?string;
    _html: ?string;
    _css: ?string;
    _summary: ?string;
    _date: ?Date;
    _bannerImageUrl: ?string;
    _bannerImageThumbnailUrl: ?string;
    _isPublished: boolean;
    _tags: Array<string>;
    _isDraft: boolean;
    _lastUpdatedDate: Date;

    static get Empty(): BlogEditPostModel {
        return new BlogEditPostModel(null, null, null, null, null, null, null, null, null, null, false, [], true, new Date());
    }

    constructor(guid: ?string,
                postId: ?number,
                longId: ?string,
                title: ?string,
                html: ?string,
                css: ?string,
                summary: ?string,
                date: ?Date,
                bannerImageUrl: ?string,
                bannerImageThumbnailUrl: ?string,
                isPublished: boolean,
                tags: Array<string>,
                isDraft: boolean,
                lastUpdatedDate: Date) {
        this._guid = guid;
        this._postId = postId;
        this._longId = longId;
        this._title = title;
        this._html = html;
        this._css = css;
        this._summary = summary;
        this._date = date;
        this._bannerImageUrl = bannerImageUrl;
        this._bannerImageThumbnailUrl = bannerImageThumbnailUrl;
        this._isPublished = isPublished;
        this._tags = tags;
        this._isDraft = isDraft;
        this._lastUpdatedDate = lastUpdatedDate;
    }

    get guid(): ?string {
        return this._guid;
    }

    set guid(value: ?string) {
        this._guid = value;
    }

    get postId(): ?number {
        return this._postId;
    }

    set postId(value: ?number) {
        this._postId = value;
    }

    /** Gets the long text-based ID of this post */
    get longId(): ?string {
        return this._longId;
    }

    set longId(value: ?string) {
        this._longId = value;
    }


    get title(): ?string {
        return this._title;
    }

    set title(value: ?string) {
        return this._title = value;
    }


    get html(): ?string {
        return this._html;
    }

    set html(value: ?string) {
        return this._html = value;
    }


    get css(): ?string {
        return this._css;
    }

    set css(value: ?string) {
        return this._css = value;
    }


    get summary(): ?string {
        return this._summary;
    }

    set summary(value: ?string) {
        return this._summary = value;
    }


    get date(): ?Date {
        return this._date;
    }

    set date(value: ?Date) {
        this._date = value;
    }


    get bannerImageUrl(): ?string {
        return this._bannerImageUrl;
    }

    set bannerImageUrl(value: ?string) {
        this._bannerImageUrl = value;
    }


    get bannerImageThumbnailUrl(): ?string {
        return this._bannerImageThumbnailUrl;
    }

    set bannerImageThumbnailUrl(value: ?string) {
        this._bannerImageThumbnailUrl = value;
    }


    get isPublished(): boolean {
        return this._isPublished;
    }

    set isPublished(value: boolean) {
        this._isPublished = value;
    }


    get tags(): Array<string> {
        return this._tags;
    }

    set tags(value: Array<string>) {
        this._tags = value;
    }



    get isDraft(): boolean {
        return this._isDraft;
    }

    set isDraft(value: boolean) {
        this._isDraft = value;
    }


    get lastUpdatedDate(): Date {
        return this._lastUpdatedDate;
    }

    set lastUpdatedDate(value: Date) {
        this._lastUpdatedDate = value;
    }



}


export default BlogEditPostModel;
