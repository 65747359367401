// @flow
import S from 'string';
import dotenv from 'dotenv';

dotenv.config();

class SharedUtils {

    static mergeRoute(...args: Array<?string>): string {

        for (let i = 0; i < args.length; i++) {
            if (i > 0) {
                args[i] = S(args[i]).stripLeft('/').s;
            }
            if (i < args.length - 1) {
                args[i] = S(args[i]).stripRight('/').s;
            }
        }

        return args.join('/');
    }

    static replaceRouteParam(route: string, param: string, paramValue: ?string | ?number) {
        let pValue = "";
        if (paramValue) {
            if (typeof paramValue === 'string') {
                pValue = paramValue;
            } else {
                pValue = paramValue.toString();
            }
        }

        return route.replace(param, pValue);
    }

    static getSafeEnvValue(key: string): string {
        if (!process.env[key]) {
            throw new Error(`Env key ${key} not found`);
        }
        return process.env[key];
    }


    static dateToShortDateString(date: ?Date | ?string): ?string {

        let format = (d: Date) => {
            return `${S(d.getFullYear()).padLeft(4, "0").s}-${S(d.getMonth() + 1).padLeft(2, "0").s}-${S(d.getDate()).padLeft(2, "0").s}`;
        };

        if (!date) {
            return null;
        } else if (typeof date === 'string') {
            return format(new Date(date));
        } else {
            return format(date);
        }
    }

    static dateToDateTimeString(date: ?Date | ?string): ?string {

        let format = (d: Date) => {
            return `${SharedUtils.dateToShortDateString(d) || ""} ${d.getHours()}:${S(d.getMinutes() + 1).padLeft(2, "0").s}:${S(d.getSeconds() + 1).padLeft(2, "0").s}`;
        };

        if (!date) {
            return null;
        } else if (typeof date === 'string') {
            return format(new Date(date));
        } else {
            return format(date);
        }
    }

    static getPostIdFromTitle(title: string): string {
        title = title.replace(/\s/g, "-");
        title = title.replace(/[`@#$%^&+=,./<>?;':"[\]{}\\|]/g, "");
        title = title.trim().toLowerCase();
        if (title.length > 0) {
            title = title.substr(0, 1).toUpperCase() + title.substr(1, title.length - 1);
        }
        return encodeURIComponent(title);
    }

}

export default SharedUtils;  