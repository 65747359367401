// @flow

class AuthStatusModel {
    _isAuthenticated: boolean;
    _name: ?string;
    _userId: ?string;
    _isAdmin: boolean;
    _isReviewer: boolean;

    static get Empty() : AuthStatusModel{
        return new AuthStatusModel(false, null, null, false, false);
    }

    constructor(isAuthenticated: boolean, name: ?string, userId: ?string, isAdmin: boolean, isReviewer: boolean) {
        this._isAuthenticated = isAuthenticated;
        this._name = name;
        this._userId = userId;
        this._isAdmin = isAdmin;
        this._isReviewer = isReviewer;
    }
    
    get isAuthenticated(): boolean {
        return this._isAuthenticated;
    } 

    set isAuthenticated(value: boolean) {
        this._isAuthenticated = value;
    }


    get name(): ?string {
        return this._name;
    }

    set name(value: ?string) {
        return this._name = value;
    }


    get userId(): ?string {
        return this._userId;
    }

    set userId(value: ?string) {
        this._userId = value;
    }


    get isAdmin(): boolean {
        return this._isAdmin;
    }

    set isAdmin(value: boolean) {
        this._isAdmin = value;
    }

    get isReviewer(): boolean {
        return this._isReviewer;
    }

    set isReviewer(value: boolean) {
        this._isReviewer = value;
    }

}

export default AuthStatusModel;
