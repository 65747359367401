// @flow

// A base class for data "page" requests
class DataPageRequestModel {
    _recordsPerPage: number;
    _pageIndex: number;
    _sortBy: ?string;
    _sortAsc: boolean;

    static get Empty() : DataPageRequestModel{
        return new DataPageRequestModel(0, 0, "", true);
    }

    constructor(recordsPerPage: number, pageIndex: number, sortBy: ?string, sortAsc: boolean) {
        this._recordsPerPage = recordsPerPage;
        this._pageIndex = pageIndex;
        this._sortBy = sortBy;
        this._sortAsc = sortAsc;
    }
 
    get recordsPerPage(): number {
        return this._recordsPerPage;
    }

    set recordsPerPage(value: number) {
        this._recordsPerPage = value;
    }


    get pageIndex(): number {
        return this._pageIndex;
    }

    set pageIndex(value: number) {
        return this._pageIndex = value;
    }


    get sortBy(): ?string {
        return this._sortBy;
    }

    set sortBy(value: ?string) {
        return this._sortBy = value;
    }


    get sortAsc(): boolean {
        return this._sortAsc;
    }

    set sortAsc(value: boolean) {
        return this._sortAsc = value;
    }

}


export default DataPageRequestModel;
