// @flow

class CutscenePlayedSearchResultModel {
    _cutsceneId: ?number;
    _cutsceneName: ?string;
    _numberOfTimesPlayed: ?number;
    _numberOfTimesAutoSkipped: ?number;
    _numberOfTimesManuallySkipped: ?number;
    _manualSkipRate: ?number;
    _averageManualSkipTimeRaw: ?number;
    _averageManualSkipTimePercent: ?number;

    static get Empty(): CutscenePlayedSearchResultModel {
        return new CutscenePlayedSearchResultModel(null, null, null);
    }

    constructor(cutsceneId: ?number,
                cutsceneName: ?string,
                numberOfTimesPlayed: ?number,
                numberOfTimesAutoSkipped: ?number,
                numberOfTimesManuallySkipped: ?number,
                manualSkipRate: ?number,
                averageManualSkipTimeRaw: ?number,
                averageManualSkipTimePercent: ?number    ) {
        this.cutsceneId = cutsceneId;
        this.cutsceneName = cutsceneName;
        this.numberOfTimesPlayed = numberOfTimesPlayed;
        this.numberOfTimesAutoSkipped = numberOfTimesAutoSkipped;
        this.numberOfTimesManuallySkipped = numberOfTimesManuallySkipped;
        this.manualSkipRate = manualSkipRate;
        this.averageManualSkipTimeRaw = averageManualSkipTimeRaw;
        this.averageManualSkipTimePercent = averageManualSkipTimePercent;
    }

    get cutsceneId(): ?number { return this._cutsceneId; }
    set cutsceneId(value: ?number) { this._cutsceneId = value; }

    get cutsceneName(): ?string { return this._cutsceneName; }
    set cutsceneName(value: ?string) { this._cutsceneName = value; }

    get numberOfTimesPlayed(): ?number { return this._numberOfTimesPlayed; }
    set numberOfTimesPlayed(value: ?number) { this._numberOfTimesPlayed = value; }

    get numberOfTimesAutoSkipped(): ?number { return this._numberOfTimesAutoSkipped; }
    set numberOfTimesAutoSkipped(value: ?number) { this._numberOfTimesAutoSkipped = value; }

    get numberOfTimesManuallySkipped(): ?number { return this._numberOfTimesManuallySkipped; }
    set numberOfTimesManuallySkipped(value: ?number) { this._numberOfTimesManuallySkipped = value; }

    get manualSkipRate(): ?number { return this._manualSkipRate; }
    set manualSkipRate(value: ?number) { this._manualSkipRate = value; }

    get averageManualSkipTimeRaw(): ?number { return this._averageManualSkipTimeRaw; }
    set averageManualSkipTimeRaw(value: ?number) { this._averageManualSkipTimeRaw = value; }

    get averageManualSkipTimePercent(): ?number { return this._averageManualSkipTimePercent; }
    set averageManualSkipTimePercent(value: ?number) { this._averageManualSkipTimePercent = value; }
}

export default CutscenePlayedSearchResultModel;
