// @flow

class SecretFoundSearchResultModel {
    _secretId: ?number;
    _secretName: ?string;
    _numberOfTimesFound: ?number;

    static get Empty(): SecretFoundSearchResultModel {
        return new SecretFoundSearchResultModel(null, null, null);
    }

    constructor(secretId: ?number,
                secretName: ?string,
                numberOfTimesFound: ?number) {
        this._secretId = secretId;
        this._secretName = secretName;
        this._numberOfTimesFound = numberOfTimesFound;
    }

    get secretId(): ?number { return this._secretId; }
    set secretId(value: ?number) { this._secretId = value; }

    get secretName(): ?string { return this._secretName; }
    set secretName(value: ?string) { this._secretName = value; }

    get numberOfTimesFound(): ?number { return this._numberOfTimesFound; }
    set numberOfTimesFound(value: ?number) { this._numberOfTimesFound = value; }
}


export default SecretFoundSearchResultModel;
