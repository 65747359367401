// @flow

// A class for retrieving string key-value pairs.
class KeyValuePairModel {
    _key: string;
    _value: string;

    static get Empty() : KeyValuePairModel{
        return new KeyValuePairModel("", "");
    }

    constructor(key: string, value: string) {
        this._key = key;
        this._value = value;
    }

    get key(): string {
        return this._key;
    }

    set key(value: string) {
        this._key = value;
    }


    get value(): string {
        return this._value;
    }

    set value(v: string) {
        return this._value = v;
    }
}


export default KeyValuePairModel;
