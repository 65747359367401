// @flow

// A class for retrieving numeric key-value pairs.
class NumericKeyValuePairModel {
    _key: number;
    _value: string;

    static get Empty() : NumericKeyValuePairModel{
        return new NumericKeyValuePairModel(0, "");
    }

    constructor(key: number, value: string) {
        this._key = key;
        this._value = value;
    }

    get key(): number {
        return this._key;
    }

    set key(value: number) {
        this._key = value;
    }


    get value(): string {
        return this._value;
    }

    set value(v: string) {
        return this._value = v;
    }
}


export default NumericKeyValuePairModel;
