// @flow

class BlogDraftPostSummaryModel {
    _guid: ?string;
    _lastUpdatedDate: Date;

    static get Empty(): BlogDraftPostSummaryModel {
        return new BlogDraftPostSummaryModel(null, new Date());
    }

    constructor(guid: ?string,
                lastUpdatedDate: Date) {
        this._guid = guid;
        this._lastUpdatedDate = lastUpdatedDate;
    }

    get guid(): ?string {
        return this._guid;
    }

    set guid(value: ?string) {
        this._guid = value;
    }

    get lastUpdatedDate(): Date {
        return this._lastUpdatedDate;
    }

    set lastUpdatedDate(value: Date) {
        this._lastUpdatedDate = value;
    }

}

export default BlogDraftPostSummaryModel;
