// @flow

class TelemetryLevelCompletionAnalysisDataModel {
    _fullLevelId: ?string;
    _averageNumberOfDeaths: ?number;
    _averageTimeSpent: ?number;


    static get Empty(): TelemetryLevelCompletionAnalysisDataModel {
        return new TelemetryLevelCompletionAnalysisDataModel(null, null, null);
    }

    constructor(fullLevelId: ?string,
                averageNumberOfDeaths: ?number,
                averageTimeSpent: ?number) {
        this._fullLevelId = fullLevelId;
        this._averageNumberOfDeaths = averageNumberOfDeaths;
        this._averageTimeSpent = averageTimeSpent;
    }

    get fullLevelId(): ?string { return this._fullLevelId; }
    set fullLevelId(value: ?string) { this._fullLevelId = value; }

    get averageNumberOfDeaths(): ?number { return this._averageNumberOfDeaths; }
    set averageNumberOfDeaths(value: ?number) { this._averageNumberOfDeaths = value; }

    get averageTimeSpent(): ?number { return this._averageTimeSpent; }
    set averageTimeSpent(value: ?number) { this._averageTimeSpent = value; }
}


export default TelemetryLevelCompletionAnalysisDataModel;
